import Discount from "../components/Discount";
import { Helmet } from "react-helmet";

const Delivery = () => {

    return (
        <section className="container">
          <Helmet>
            <title>Условия доставки букетов и композиций. Срочная доставка букетов по Королеву</title>
            <meta name="description" content="Условия доставки букетов по Королеву, Мытищам, Пушкино, Москве, Щелково. Время доставки букетов. Срочная доставка цветов." />
          </Helmet>
            <div class="delivery text-white text-start mt-2 mb-4">
                <h1 className="text-center">Доставка</h1>
                <p>Мы стремимся, чтобы каждый букет и композиция прибыли к Вам точно в срок и в безупречном виде. Узнать больше об условиях и времени доставки нашей студии можно на этой странице.</p>

                <h2 className="text-center">Условия</h2>
                <ul>
                    <li><strong>Доставка по городам:</strong> Мы доставляем букеты и композиции по Королёву, Мытищам, Пушкино, Щелково и Москве.</li>
                    <li><strong>Стоимость доставки:</strong> При заказе от 4000₽ доставка по Королеву <strong>бесплатно!</strong> До 4000 ₽ — 299 ₽.</li>
                    <li><strong>Минимальный заказ:</strong> Для оформления заказа на доставку минимальная сумма — 2000 ₽.</li>
                </ul>

                <div class="table-responsive">
                    <table class="table table-bordered text-center text-white">
                    <thead>
                        <tr>
                        <th>Город</th>
                        <th>Королев</th>
                        <th>Мытищи</th>
                        <th>Пушкино</th>
                        <th>Щелково</th>
                        <th>Москва*</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Заказ до 4000 руб.</td>
                        <td>299 руб.</td>
                        <td>449 руб.</td>
                        <td>590 руб.</td>
                        <td>590 руб.</td>
                        <td>690 руб.</td>
                        </tr>
                        <tr>
                        <td>Заказ больше 4000 руб.</td>
                        <td><strong>Бесплатно</strong></td>
                        <td>299 руб.</td>
                        <td>349 руб.</td>
                        <td>349 руб.</td>
                        <td>499 руб.</td>
                        </tr>
                    </tbody>
                    </table>
                </div>

                <h2 className="text-center">Время</h2>
                <ul>
                    <li><strong>Ежедневная доставка:</strong> Мы доставляем заказы ежедневно с 9:00 до 21:00.</li>
                    <li><strong>Ранние доставки:</strong> В праздничные и особые дни доставка может начинаться раньше. Пожалуйста, уточняйте условия заранее.</li>
                    <li><strong>Срочная доставка:</strong> При необходимости мы готовы выполнить заказ в короткие сроки — свяжитесь с нами для уточнения деталей. Минимальное время доставки от <strong>25 минут!</strong></li>
                </ul>

            </div>
            <Discount />
        </section>
    );
};

export default Delivery;